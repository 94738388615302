import React from 'react';

const Dashboard = ({ userProfile }) => {
  if (!userProfile) {
    return <div>Loading...</div>; // Fallback if userProfile is not yet available
  }

  return (
    <div>
      <h2>Welcome to the Dashboard</h2>
      <p>Hello, {userProfile.username}!</p>
      {/* Display more user information if needed */}
    </div>
  );
};

export default Dashboard;
