// src/services/api.js
import axios from 'axios';

// Create an axios instance
const api = axios.create({
  baseURL: 'https://beta.bitders.com/api.php', // Base URL for all requests
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded', // Default content type
  },
});

// Add a request interceptor to include CSRF token if it's set
api.interceptors.request.use(
  (config) => {
    // Get the csrf token from local storage or any other storage
    const csrfToken = localStorage.getItem('csrfToken');
    if (csrfToken) {
      config.headers['csrftoken'] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
