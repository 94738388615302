import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import api from './services/api'; // Import the axios instance
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

const App = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true); // State to show a loading indicator

  const fetchUserProfile = async (token) => {
    try {
      const response = await api.post('', {
        type: 'userinfo', // Adjust with your actual endpoint to fetch user profile
        user_id: token, // Send the stored token to retrieve the user profile
      });

      if (response.data.result) {
        setUserProfile(response.data.profile); // Update state with user profile
      } else {
        localStorage.removeItem('authToken'); // Remove invalid token
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      setLoading(false); // Stop loading after API call
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken'); // Retrieve token from local storage
    if (token) {
      fetchUserProfile(token); // Fetch user profile if token exists
    } else {
      setLoading(false); // No token, stop loading
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login setUserProfile={setUserProfile} />} />
        <Route path="/dashboard" element={userProfile ? <Dashboard userProfile={userProfile} /> : <Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
