import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api'; // Import the axios instance

const Login = ({ setUserProfile }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const fetchCsrfToken = async () => {
    try {
      const response = await api.post('', {
        type: 'get_csrf_token',
        token_type: 'login',
      });
      const token = response.data.token;
      localStorage.setItem('csrfToken', token); // Store CSRF token in local storage
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('', {
        type: 'login',
        username: username,
        password: password,
      });

      if (response.data.result) {
        localStorage.setItem('token', response.data.token); // Store the authentication token
        setUserProfile(response.data.profile); // Update React state with user profile
        navigate('/dashboard');
      } else {
        alert(response.data.message || 'Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('An error occurred during login. Please try again.');
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
          />
        </div>
        <div>
          <label>Password:</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
